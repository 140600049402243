.navigationContainer {
	grid-column: span 2 / span 2;
	width: 100%;
	height: 100%;
	position: relative;
}

.navigationContainerList {
	grid-column: span 2 / span 2;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding-left: 32px;
	padding-right: 32px;
}
