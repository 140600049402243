.section {
	position: relative;
	width: 100%;
}

.section[data-theme="light"] {
	background-color: #f6fafe;
}

.section[data-theme="dark"] {
	background-color: #0a2540;
}

.section[data-theme="white"] {
	background-color: #ffffff;
}

.sectionTopRuler {
	background: linear-gradient(
		90deg,
		rgba(226, 232, 240, 100),
		rgba(226, 232, 240, 100) 50%,
		transparent 0,
		transparent
	);
	background-size: 10px 1px;
	top: 0px;
	left: 0px;
	right: 0px;
	position: absolute;
	width: 100%;
	height: 1px;
	background-position: bottom;
}

.smallSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.smallSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.smallSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.mediumSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.mediumSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.mediumSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.largeSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.largeSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.largeSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.smallSection {
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
	position: relative;
	z-index: 50;
}

.mediumSection {
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
	position: relative;
	z-index: 50;
}

.largeSection {
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;
	z-index: 50;
}
