.menuVarWrapper {
	height: 100%;
	width: 100%;
}

.smallWrapper {
	height: 100%;
	width: 100%;
	position: relative;
	padding-left: 16px;
	padding-right: 16px;
}

.smallMenuWrapper {
	position: absolute;
	z-index: 50;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	background-color: white;
}

.smallMenu {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	row-gap: 16px;
	align-items: center;
	justify-items: center;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 16px;
}

.smallOverlayWrapper {
	position: fixed;
	z-index: 40;
	width: 100vw;
	height: 100vh;
	background-color: black;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
}

.mediumWrapper {
	height: 100%;
	width: 100%;
	position: relative;
	padding-left: 16px;
	padding-right: 16px;
}

.mediumMenuWrapper {
	position: absolute;
	z-index: 50;
	top: 80px;
	left: 0px;
	right: 0px;
	width: 100%;
	background-color: white;
}

.mediumMenu {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	row-gap: 16px;
	align-items: center;
	justify-items: center;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 16px;
}

.mediumOverlayWrapper {
	position: fixed;
	z-index: 40;
	width: 100vw;
	height: 100vh;
	background-color: black;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
}

.text {
	font-size: var(--size-navigation);
	font-weight: var(--weight-navigation);
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #0a2540;
	justify-self: start;
	padding: 1px 6px 1px 6px;
	margin-top: 16px;
}

.text2 {
	font-size: var(--size-navigation);
	font-weight: var(--weight-navigation);
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #0a2540;
	justify-self: start;
	padding: 1px 6px 1px 6px;
}
