.headerWrapper {
	position: relative;
	width: 100%;
	z-index: 100;
}

.headerBottomRuler {
	background: linear-gradient(
		90deg,
		rgba(226, 232, 240, 100),
		rgba(226, 232, 240, 100) 50%,
		transparent 0,
		transparent
	);
	background-size: 10px 1px;
	top: 79px;
	left: 0px;
	right: 0px;
	position: absolute;
	width: 100%;
	height: 1px;
	background-position: bottom;
}

.smallWrapper {
	position: relative;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.mediumWrapper {
	position: relative;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.largeWrapper {
	position: relative;
	width: 100%;
	z-index: 50;
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
}

.smallGridWrapper {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	height: 80px;
	width: 100%;
	z-index: 50;
}

.mediumGridWrapper {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	height: 80px;
	width: 100%;
	z-index: 50;
}

.largeGridWrapper {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	height: 80px;
	width: 100%;
	z-index: 50;
}

.smallLogoContainer {
	grid-column: span 3 / span 3;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
	padding-left: 16px;
}

.largeLogoContainer {
	grid-column: span 1 / span 1;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
	padding-left: 16px;
}

.logoFlex {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.logoText {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 8px;
}

.logo {
	width: auto;
	height: 32px;
	color: #2563eb;
}

.logoTop {
	font-family: var(--font-display);
	font-size: var(--size-logo);
	font-weight: var(--weight-logo);
	letter-spacing: var(--tracking-logo);
	line-height: var(--leading-logo);
	color: #2563eb;
	font-family: var(--font-display);
}

.logoBottom {
	font-family: var(--font-display);
	font-size: var(--size-logo);
	font-weight: var(--weight-logo-var);
	letter-spacing: var(--tracking-logo);
	line-height: var(--leading-logo);
	color: #2563eb;
	font-family: var(--font-display);
}

.navigationContainer {
	grid-column: span 2 / span 2;
	width: 100%;
	height: 100%;
}

.navigationContainerList {
	grid-column: span 2 / span 2;
	display: flex;
	flex-direction: row;
	width: min-content;
	height: 100%;
	align-items: center;
	justify-content: space-between;
}

.navigationItem {
	font-size: 15px;
	font-weight: 500;
	color: #0a2540;
}

.actionContainer {
	grid-column: span 1 / span 1;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
	padding-right: 16px;
}
