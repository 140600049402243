.navigationItem {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.navigationItemTest {
	position: relative;
	width: max-content;
	font-size: var(--size-navigation);
	font-weight: var(--weight-navigation);
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #0a2540;
}

.navigationMenuTest {
	position: absolute;
	top: 60px;
	left: 0px;
	right: 0px;
	width: 100%;
}
