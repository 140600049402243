.action {
	color: #ffffff;
	white-space: nowrap;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius: 9999px;
	font-size: 15px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	z-index: 60;
}

.action:hover {
	cursor: pointer;
}

.arrow {
	width: 16px;
	height: 10px;
	color: #ffffff;
}
