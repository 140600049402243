.subItemContainerTest {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	align-items: center;
	justify-items: center;
	width: 100%;
	row-gap: 12px;
	column-gap: 12px;
	padding-left: 32px;
	padding-right: 32px;
	padding-top: 32px;
	padding-bottom: 32px;
}

.navigationMenu {
	position: absolute;
	z-index: 50;
	top: 80px;
	left: 0px;
	right: 0px;
	width: 100%;
	background-color: white;
}
