.subItem {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}

.subItemTest {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.subItemTitle {
	font-size: var(--size-navigation);
	font-weight: var(--weight-navigation);
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #0a2540;
	margin-left: 16px;
}

.subItemText {
	margin-top: 4px;
	font-size: var(--size-subitem);
	font-weight: var(--weight-subitem);
	letter-spacing: var(--tracking-subitem);
	line-height: var(--leading-subitem);
	color: #425466;
}

.imageWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	flex: none;
	color: white;
	padding: 8px;
	border-radius: 8px;
	background-color: #2e63eb;
}

.text {
	font-size: var(--size-button);
	font-weight: var(--weight-button);
	letter-spacing: var(--tracking-button);
	line-height: var(--leading-button);
	color: #0a2540;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 14ch;
}

.imageWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	flex: none;
	padding: 8px;
	border-radius: 8px;
	color: white;
}

.arrow {
	width: 10px;
	height: 10px;
	color: #0a2540;
	margin-top: 1px;
	margin-left: 8px;
	display: block;
	flex: none;
}

.textWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
