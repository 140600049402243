.grid {
	width: 100%;
}

.smallGrid {
	display: grid;
	align-items: flex-start;
}

.smallGrid[data-column-number="1"] {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

.smallGrid[data-column-number="2"] {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.smallGrid[data-column-number="4"] {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.smallGrid[data-row-number="1"] {
	grid-template-rows: repeat(1, minmax(0, auto));
}

.smallGrid[data-row-number="2"] {
	grid-template-rows: repeat(2, minmax(0, auto));
}

.smallGrid[data-row-number="4"] {
	grid-template-rows: repeat(4, minmax(0, auto));
}

.smallGrid[data-column-gap="none"] {
	column-gap: 0px;
}

.smallGrid[data-column-gap="small"] {
	column-gap: 20px;
}

.smallGrid[data-column-gap="medium"] {
	column-gap: 60px;
}

.smallGrid[data-column-gap="large"] {
	column-gap: 100px;
}

.smallGrid[data-row-gap="none"] {
	row-gap: 0px;
}

.smallGrid[data-row-gap="small"] {
	row-gap: 20px;
}

.smallGrid[data-row-gap="medium"] {
	row-gap: 60px;
}

.smallGrid[data-row-gap="large"] {
	row-gap: 100px;
}

.smallGrid[data-width="25"] {
	width: 25%;
}

.smallGrid[data-width="50"] {
	width: 50%;
}

.smallGrid[data-width="75"] {
	width: 75%;
}

.smallGrid[data-width="100"] {
	width: 100%;
}

.smallGrid[data-justify="start"] {
	justify-items: start;
}

.smallGrid[data-justify="end"] {
	justify-items: end;
}

.smallGrid[data-justify="center"] {
	justify-items: center;
}

.smallGrid[data-justify="stretch"] {
	justify-items: stretch;
}

.smallGrid[data-align="start"] {
	align-items: start;
}

.smallGrid[data-align="end"] {
	align-items: end;
}

.smallGrid[data-align="center"] {
	align-items: center;
}

.smallGrid[data-align="stretch"] {
	align-items: stretch;
}

.mediumGrid {
	display: grid;
	align-items: flex-start;
}

.mediumGrid[data-column-number="1"] {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mediumGrid[data-column-number="2"] {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.mediumGrid[data-column-number="4"] {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.mediumGrid[data-row-number="1"] {
	grid-template-rows: repeat(1, minmax(0, auto));
}

.mediumGrid[data-row-number="2"] {
	grid-template-rows: repeat(2, minmax(0, auto));
}

.mediumGrid[data-row-number="4"] {
	grid-template-rows: repeat(4, minmax(0, auto));
}

.mediumGrid[data-column-gap="none"] {
	column-gap: 0px;
}

.mediumGrid[data-column-gap="small"] {
	column-gap: 20px;
}

.mediumGrid[data-column-gap="medium"] {
	column-gap: 60px;
}

.mediumGrid[data-column-gap="large"] {
	column-gap: 100px;
}

.mediumGrid[data-row-gap="none"] {
	row-gap: 0px;
}

.mediumGrid[data-row-gap="small"] {
	row-gap: 20px;
}

.mediumGrid[data-row-gap="medium"] {
	row-gap: 60px;
}

.mediumGrid[data-row-gap="large"] {
	row-gap: 100px;
}

.mediumGrid[data-width="25"] {
	width: 25%;
}

.mediumGrid[data-width="50"] {
	width: 50%;
}

.mediumGrid[data-width="75"] {
	width: 75%;
}

.mediumGrid[data-width="100"] {
	width: 100%;
}

.mediumGrid[data-justify="start"] {
	justify-items: start;
}

.mediumGrid[data-justify="end"] {
	justify-items: end;
}

.mediumGrid[data-justify="center"] {
	justify-items: center;
}

.mediumGrid[data-justify="stretch"] {
	justify-items: stretch;
}

.mediumGrid[data-align="start"] {
	align-items: start;
}

.mediumGrid[data-align="end"] {
	align-items: end;
}

.mediumGrid[data-align="center"] {
	align-items: center;
}

.mediumGrid[data-align="stretch"] {
	align-items: stretch;
}

.largeGrid {
	display: grid;
	align-items: flex-start;
}

.largeGrid[data-column-number="1"] {
	grid-template-columns: repeat(1, 1fr);
}

.largeGrid[data-column-number="2"] {
	grid-template-columns: repeat(2, 1fr);
}

.largeGrid[data-column-number="4"] {
	grid-template-columns: repeat(4, 1fr);
}

.largeGrid[data-row-number="1"] {
	grid-template-rows: repeat(1, auto);
}

.largeGrid[data-row-number="2"] {
	grid-template-rows: repeat(2, auto);
}

.largeGrid[data-row-number="4"] {
	grid-template-rows: repeat(4, auto);
}

.largeGrid[data-column-gap="none"] {
	column-gap: 0px;
}

.largeGrid[data-column-gap="small"] {
	column-gap: 20px;
}

.largeGrid[data-column-gap="medium"] {
	column-gap: 60px;
}

.largeGrid[data-column-gap="large"] {
	column-gap: 100px;
}

.largeGrid[data-row-gap="none"] {
	row-gap: 0px;
}

.largeGrid[data-row-gap="small"] {
	row-gap: 20px;
}

.largeGrid[data-row-gap="medium"] {
	row-gap: 60px;
}

.largeGrid[data-row-gap="large"] {
	row-gap: 100px;
}

.largeGrid[data-width="25"] {
	width: 25%;
}

.largeGrid[data-width="50"] {
	width: 50%;
}

.largeGrid[data-width="75"] {
	width: 75%;
}

.largeGrid[data-width="100"] {
	width: 100%;
}

.largeGrid[data-justify="start"] {
	justify-items: start;
}

.largeGrid[data-justify="end"] {
	justify-items: end;
}

.largeGrid[data-justify="center"] {
	justify-items: center;
}

.largeGrid[data-justify="stretch"] {
	justify-items: stretch;
}

.largeGrid[data-align="start"] {
	align-items: start;
}

.largeGrid[data-align="end"] {
	align-items: end;
}

.largeGrid[data-align="center"] {
	align-items: center;
}

.largeGrid[data-align="stretch"] {
	align-items: stretch;
}
