.body {
	width: 100%;
}

.smallBody {
	font-size: var(--size-body-small);
	font-weight: var(--weight-body-small);
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #425466;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}

.mediumBody {
	font-size: var(--size-body-small);
	font-weight: var(--weight-body-small);
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #425466;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}

.largeBody {
	font-size: var(--size-body);
	font-weight: var(--weight-body);
	letter-spacing: var(--tracking-body);
	line-height: var(--leading-body);
	color: #425466;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}
