.picture {
	position: relative;
	width: 100%;
	height: 100%;
}

.smallPicture {
	position: relative;
	width: 100%;
	min-height: 300px;
	height: 100%;
}

.mediumPicture {
	position: relative;
	width: 100%;
	min-height: 300px;
	height: 100%;
}

.largePicture {
	position: relative;
	width: 100%;
	min-height: 400px;
	height: 100%;
}

.smallPictureOverlay {
	background-color: #0a2540;
	mask-image: linear-gradient(0deg, #000 15%, rgba(0, 0, 0, 0.5) 54.75%);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20;
	border-radius: 16px;
	overflow: hidden;
}

.mediumPictureOverlay {
	background-color: #0a2540;
	mask-image: linear-gradient(0deg, #000 15%, rgba(0, 0, 0, 0.5) 54.75%);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20;
	border-radius: 16px;
	overflow: hidden;
}

.largePictureOverlay {
	background-color: #0a2540;
	mask-image: linear-gradient(0deg, #000 15%, rgba(0, 0, 0, 0.5) 54.75%);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20;
	border-radius: 16px;
	overflow: hidden;
}

.smallPictureContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
		0 18px 36px -18px rgba(0, 0, 0, 0.3);
	border: 1px solid rgb(227, 232, 238);
}

.mediumPictureContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
		0 18px 36px -18px rgba(0, 0, 0, 0.3);
	border: 1px solid rgb(227, 232, 238);
}

.largePictureContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
		0 18px 36px -18px rgba(0, 0, 0, 0.3);
	border: 1px solid rgb(227, 232, 238);
}

.largePictureContainer img {
	max-width: 100%;
	width: auto;
	height: auto;
	min-height: 100%;
}

.mediumPictureContainer img {
	max-width: 100%;
	width: auto;
	height: auto;
	min-height: 100%;
}

.smallPictureContainer img {
	max-width: 100%;
	width: auto;
	height: auto;
	min-height: 100%;
}

.smallPictureContainerImage {
	object-fit: cover;
}

.mediumPictureContainerImage {
	object-fit: cover;
}

.largePictureContainerImage {
	object-fit: cover;
}
