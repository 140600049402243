.action {
	background-color: #2e63eb;
	color: #ffffff;
	white-space: nowrap;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 9999px;
	font-size: var(--size-button);
	letter-spacing: var(--tracking-button);
	line-height: var(--leading-button);
	font-weight: var(--weight-button);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.action:hover {
	cursor: pointer;
}

.arrow {
	width: 10px;
	height: 10px;
	color: white;
	margin: auto auto auto 8px;
	display: block;
}

.text {
	display: block;
}

.buttonWrapper {
	padding-left: 16px;
	padding-right: 16px;
}
