.wrapper {
	width: 100%;
	position: relative;
	z-index: 20;
	background-color: #ffffff;
}

.footerTopRuler {
	background: linear-gradient(
		90deg,
		rgba(226, 232, 240, 100),
		rgba(226, 232, 240, 100) 50%,
		transparent 0,
		transparent
	);
	background-size: 10px 1px;
	top: 0px;
	left: 0px;
	right: 0px;
	position: absolute;
	width: 100%;
	height: 1px;
	background-position: bottom;
}

.smallWrapper {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-items: start;
	align-items: start;
	z-index: 20;
	position: relative;
	width: 100%;
	height: 100%;
	row-gap: 40px;
	padding-top: 80px;
	padding-bottom: 80px;
	padding-left: 16px;
	padding-right: 16px;
}

.mediumWrapper {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-items: start;
	align-items: start;
	z-index: 20;
	position: relative;
	width: 100%;
	height: 100%;
	row-gap: 40px;
	padding-top: 80px;
	padding-bottom: 80px;
	padding-left: 16px;
	padding-right: 16px;
}

.largeWrapper {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	justify-items: start;
	align-items: start;
	max-width: 1080px;
	margin-right: auto;
	margin-left: auto;
	z-index: 20;
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 80px;
	padding-bottom: 80px;
}

.logoContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	justify-content: flex-start;
}

.logoFlex {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.logoText {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 8px;
}

.logo {
	width: auto;
	height: 32px;
	color: #2563eb;
}

.logoTop {
	font-family: var(--font-display);
	font-size: var(--size-logo);
	font-weight: var(--weight-logo);
	letter-spacing: var(--tracking-logo);
	line-height: var(--leading-logo);
	color: #2563eb;
	font-family: var(--font-display);
}

.logoBottom {
	font-family: var(--font-display);
	font-size: var(--size-logo);
	font-weight: var(--weight-logo-var);
	letter-spacing: var(--tracking-logo);
	line-height: var(--leading-logo);
	color: #2563eb;
	font-family: var(--font-display);
}

.navigationTitle {
	font-size: var(--size-navigation);
	font-weight: var(--weight-navigation);
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #0a2540;
	margin-bottom: 8px;
}

.column {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.column2 {
	height: 100%;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.smallColumn2 {
	grid-column: span 2 / span 2;
	width: 100%;
	height: 100%;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.navigationLink {
	font-size: var(--size-navigation);
	font-weight: 500;
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #364657;
	margin-top: 4px;
	margin-bottom: 4px;
}

@media screen and (max-width: 767px) {
	.navigationLink {
		padding: 10px 0
	}
}

.copyright {
	font-size: var(--size-copyright);
	font-weight: 400;
	letter-spacing: var(--tracking-copyright);
	line-height: var(--leading-copyright);
	color: #364657;
}

.copyright2 {
	font-size: var(--size-navigation);
	font-weight: 400;
	letter-spacing: var(--tracking-navigation);
	line-height: var(--leading-navigation);
	color: #364657;
	margin-top: 40px;
}
