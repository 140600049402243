.ruler {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;
	overflow: visible;
}

.smallRuler {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-right: 16px;
	padding-left: 16px;
}

.smallRulerCol {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		);
	background-position: left, right;
	background-size: 1px 10px, 1px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}

.mediumRuler {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-right: 16px;
	padding-left: 16px;
}

.mediumRulerColLeft {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		);
	background-position: left, right;
	background-size: 1px 10px, 0.5px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}

.mediumRulerColRight {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		);
	background-position: left, right;
	background-size: 0.5px 10px, 1px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}

.largeRuler {
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.largeRulerColLeft {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		);
	background-position: left, right;
	background-size: 1px 10px, 0.5px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}

.largeRulerColRight {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 100%,
			rgba(255, 255, 255, 0) 0%
		);
	background-position: left, right;
	background-size: 0.5px 10px, 1px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}

.largeRulerColInner {
	background-image: linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		),
		linear-gradient(
			rgba(226, 232, 240, 100) 50%,
			rgba(255, 255, 255, 0) 50%
		);
	background-position: left, right;
	background-size: 0.5px 10px, 0.5px 10px;
	background-repeat: repeat-y;
	height: 100%;
	width: 100%;
}
